<template>
  <ui-component-modal
    modalTitle="Edit invoice address"
    :isSaving="isSaving"
    :isSavingSuccess="isSavingSuccess"
    :isSavingError="isSavingError"
    :hideFooterButtons="isSavingError || isSavingSuccess"
    :onClickCancel="onClickCancel"
    :onClickSave="save"
    :showModal="showModal"
    @closeModal="onClickCancel"
  >
    <template v-slot:content>
      <table class="table is-fullwidth is-striped">
        <tbody>
          <tr>
            <td>Company</td>
            <td>
              <input type="text" v-model="mInvoice.CompanyName" class="input" />
            </td>
          </tr>
          <tr>
            <td>Attn.</td>
            <td>
              <input type="text" v-model="mInvoice.SendToName" class="input" />
            </td>
          </tr>
          <tr>
            <td>Email</td>
            <td>
              <input type="text" v-model="mInvoice.EmailTo" class="input" />
            </td>
          </tr>
          <tr>
            <td>PO number</td>
            <td>
              <input type="text" v-model="mInvoice.PoNumber" class="input" />
            </td>
          </tr>
          <tr>
            <td>VAT number</td>
            <td>
              <input type="text" v-model="mInvoice.VatNumber" class="input" />
            </td>
          </tr>
          <tr>
            <td>Address</td>
            <td>
              <input type="text" v-model="mInvoice.Address" class="input" />
            </td>
          </tr>
          <tr>
            <td>Postal code</td>
            <td>
              <input type="text" v-model="mInvoice.PostalCode" class="input" />
            </td>
          </tr>
          <tr>
            <td>City</td>
            <td>
              <input type="text" v-model="mInvoice.City" class="input" />
            </td>
          </tr>
        </tbody>
      </table>
    </template>
  </ui-component-modal>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import invoiceProvider from '@/providers/invoice'

export default {
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    onClickCancel: {
      type: Function,
      required: true,
    },
  },

  data() {
    return {
      isSaving: false,
      isSavingError: false,
      isSavingSuccess: false,
      mInvoice: null,
      noErrors: true,
    }
  },

  computed: {
    ...mapState('invoiceStore', ['invoice']),
  },

  created() {
    this.mInvoice = JSON.parse(JSON.stringify(this.invoice))
  },

  methods: {
    ...mapMutations('invoiceStore', ['setInvoice']),

    /**
     * Save
     */
    save() {
      let self = this
      // Do saving then close modal
      if (!self.isSaving && this.validation()) {
        self.isSaving = true

        invoiceProvider.methods
          .saveInvoiceAddress(
            self.invoice.Id,
            self.mInvoice.Address,
            self.mInvoice.PostalCode,
            self.mInvoice.City,
            self.mInvoice.PoNumber,
            self.mInvoice.CompanyName,
            self.mInvoice.SendToName,
            self.mInvoice.EmailTo,
            self.mInvoice.VatNumber
          )
          .then((response) => {
            if (response.status === 200) {
              self.isSavingSuccess = true

              self.setInvoice(response.data)

              setTimeout(() => {
                self.onClickCancel()
              }, 1500)
            }
          })
          .catch((e) => {
            self.isSavingError = true
          })
          .finally(() => {
            self.isSaving = false
          })
      }
    },

    validation() {
      this.noErrors = true
      for (let i in this.formFields) {
        this.formFields[i].error = ''
        if (this.formFields[i].required) {
          switch (this.formFields[i].fieldType) {
            case 'text':
              if (!this.formFields[i].value) {
                this.noErrors = false
                this.formFields[i].error = 'This field is required.'
              }
              break
            case 'number':
              if (isNaN(this.formFields[i].value)) {
                this.noErrors = false
                this.formFields[i].error = 'Only numbers are allowed.'
              }
              break
            case 'email':
              if (!this.formFields[i].value) {
                this.hasErrors = false
                this.formFields[i].error = 'This field is required.'
              }

              if (
                !/^([a-zA-Z0-9_\.\-\+])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/.test(
                  this.formFields[i].value
                )
              ) {
                this.noErrors = false
                this.formFields[i].error =
                  'Specified e-mail address is incorrect.'
              }
              break
            case 'date':
              break
          }
        }
      }
      return this.noErrors
    },
  },
}
</script>
